export const Tientech_getCookie = name => {
  try {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) {
      const cookie = parts.pop().split(';').shift()
      let cookiestr = ''
      cookiestr = decodeURIComponent(cookie)
      cookiestr = decodeURIComponent(cookiestr)
      try {
        cookiestr = JSON.parse(cookiestr)
      } catch (error) {
        // error
      }
      return cookiestr
    }
    return null
  } catch (error) {
    console.log(error)
    return null
  }
}

export const Tientech_getPageRandomNumber = () => {
  try {
    return parseInt(Math.random() * Number.MAX_SAFE_INTEGER, 10)
  } catch (error) {
    return 0
  }
}

export const Tientech_delay = async delayInms =>
new Promise(resolve => {
  setTimeout(() => {
    resolve(2)
  }, delayInms)
})
